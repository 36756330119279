.InspectionRecordDetailContainer {
  background-color: #f5f5f8;
  &-top {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 16px;
    &__left {
      &__class {
        font-size: 20px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 600;
        color: #081c38;
        position: relative;
      }
      &__class::after {
        content: '';
        display: block;
        margin: 0 auto;
        width: 16px;
        height: 3px;
        background: linear-gradient(90deg, #4ca9ff 0%, #4579ff 100%);
        position: absolute;
        bottom: -8px;
        border-radius: 2px;
        left: 0;
        right: 0;
      }
    }
    &__right {
      width: 121px;
      height: 31px;
      line-height: 31px;
      background: linear-gradient(90deg, #33b8ff 0%, #2499ff 100%);
      border-radius: 17px;
      font-size: 14px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
    }
  }
  &-con {
    background: #f5f5f8;
    border-radius: 20px 20px 0px 0px;
    padding-bottom: 16px;
    &__item {
      background: #ffffff;
      border-radius: 10px;
      margin: 16px;
      padding: 14px 19px 17px 19px;
      &__top {
        display: flex;
        justify-content: space-between;
        &__left {
          display: flex;
          &__icon {
            width: 16px;
            height: 18.6px;
            margin-top: 0.8px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &__name {
            font-size: 15px;
            font-family:
              PingFangSC,
              PingFang SC;
            font-weight: 500;
            color: #081c38;
            margin-left: 8px;
          }
        }
        &__edit {
          font-size: 14px;
          font-family:
            PingFangSC,
            PingFang SC;
          color: #499aff;
          margin-right: 12px;
        }
        &__delete {
          margin-top: 2px;
          width: 16px;
          height: 16px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &__right {
          font-size: 12px;
          font-family:
            PingFangSC,
            PingFang SC;
          color: #bcc1c7;
        }
      }
      &__con {
        margin-top: 18px;
        &__cur {
          height: 40px;
          line-height: 40px;
          background: #fafafb;
          border-radius: 6px;
          margin-bottom: 12px;
          font-size: 14px;
          font-family:
            PingFangSC,
            PingFang SC;

          color: #434e5e;
          padding-left: 15px;
        }
      }
      &__message {
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        color: #8c939d;
        margin-top: 17px;
      }
      &__imgBox {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        &__img {
          width: 70px;
          height: 70px;
          border-radius: 6px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &__comment {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        &__time {
          font-size: 12px;
          font-family:
            PingFangSC,
            PingFang SC;
          color: #bcc1c7;
        }
        &__icon {
          width: 13px;
          height: 13px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &__commentDetail {
        margin-top: 10px;
        &__item {
          margin-bottom: 8px;
          padding-left: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 10px;
          background: #f2f2f2;
          border-radius: 5px;
          font-size: 12px;
          font-family:
            PingFangSC,
            PingFang SC;
          font-weight: 500;
          color: #909090;
          word-wrap: break-word;
        }
      }
    }
  }
  &-pull {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}
.commentInput {
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #1677ff;
  font-size: 14px;
}
.commentBtn {
  cursor: pointer;
  font-size: 15px;
  font-family:
    PingFangSC,
    PingFang SC;
  font-weight: 400;
  color: #1677ff;
  margin-left: 16px;
  min-width: 30px;
  margin-top: 5px;
}
.back-up {
  position: fixed;
  bottom: 80px;
  right: 8px;
  cursor: pointer;

  img {
    width: 44px;
    height: 44px;
  }
}

.deleteModal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 268px;
  height: 100px;
  background: #ffffff;
  border-radius: 12px;
  &__title {
    font-size: 15px;
    font-family:
      PingFangSC,
      PingFang SC;
    font-weight: 500;
    color: #081c38;
  }
  &__btn {
    display: flex;
    margin-top: 26px;
    &__left {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      width: 96px;
      height: 34px;
      line-height: 34px;
      background: #f2f3f5;
      border-radius: 17px;
      font-size: 14px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 500;
      color: #8c939d;
    }
    &__right {
      margin-left: 13px;
      width: 96px;
      height: 34px;
      line-height: 34px;
      background: linear-gradient(90deg, #757bff 0%, #4d77ff 100%);
      border-radius: 17px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      font-size: 14px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

@root-entry-name: default;