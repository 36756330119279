.tags-label {
  .adm-list-item-content{
    border-bottom: none!important;
  }
}
.tags-input {
  .adm-list-item-content-main {
    padding: 0px;
  }

  .adm-form-item-child-position-normal {
    text-align: left !important;
  }

  .tag-list {
    padding-bottom: 12px;

    .my-tag {
      height: 28px;
      background: #F5F5F8;
      border-radius: 6px;
      font-size: 13px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #434E5E;
      border: none;
      padding: 5px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
@root-entry-name: default;