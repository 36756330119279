.nopadding {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.noscroll {
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

// NavBar样式
.adm-nav-bar {
  background: #fff;

  .adm-nav-bar-title {
    font-size: 16px;
    font-weight: 500;
    color: #081C38;
  }

  .adm-nav-bar-back-arrow {
    font-size: 16px;
    font-weight: 600;
    color: #8FA0B8;
  }
}

.check-icon {
  width: 18px;
  height: 18px;
}

.last-form-item {
  .adm-list-item-content {
    border-bottom: none !important;
  }
}

.adm-list-item-content-arrow {
  font-size: 17px !important;
  color: #BCC1C7 !important;
}

html {
  @media only screen and (min-width: 1024px) {
    & {
      background: rgba(0, 0, 0, 0.15);
    }
  }
}

.adm-nav-bar {
  display: none !important;
}
@root-entry-name: default;