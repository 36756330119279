.place-tags-form_item {
  .adm-list-item .adm-list-item-content {
    border-bottom: none !important;
  }

  .tags-input {
    .adm-list-item-content-main {
      padding: 0px;
    }

    .adm-form-item-child-position-normal {
      text-align: left !important;
    }

    .tag-list {
      padding-bottom: 12px;
      display: flex;
      flex-direction: column;
      gap: 14px;

      .place-type-box {
        background: #FAFAFB;
        border-radius: 5px;
        padding-inline: 4px;
        padding-block: 12px;

        .tag-collapse-box {
          max-height: 78px;
          overflow: hidden;
        }

        .place-type {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #434E5E;
          margin-bottom: 12px;
          padding-inline: 12px;
        }
      }

      .my-tag {
        height: 28px;
        background: #ECECEF;
        border-radius: 6px;
        font-size: 13px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #434E5E;
        border: none;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .collapse {
        margin-top: 10px;
        height: 26px;
        background: #ECECEF;
        border-radius: 6px;
        font-size: 13px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #818183;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        cursor: pointer;

        img {
          width: 13px;
          height: 13px;
        }

        .up {
          transform: rotate(180deg);
        }

        .down {
          transform: rotate(0deg);
        }
      }
    }
  }
}
@root-entry-name: default;