.InspectionRecordFormContainer {
  background-color: #f5f5f8;
  padding-bottom: 30px;
  &-top {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 15px 0 15px;
    &__item {
      border-bottom: 1px solid #ebeff4;
      padding-bottom: 14px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
      &__left {
        font-size: 15px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 500;
        color: #8c939d;
      }
      &__right {
        font-size: 15px;
        font-family:
          PingFangSC,
          PingFang SC;
        color: #d2d4d6;
      }
      &__value {
        font-size: 15px;
        font-family:
          PingFangSC,
          PingFang SC;
        color: #152e5a;
      }
    }
    &__item:last-child {
      border: 0;
    }
  }
  &-label {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 15px 15px 15px;
    &__top {
      .custom-search-bar {
        border-radius: 17px;
      }
    }
    &__con {
      margin-top: 15px;

      &__item {
        height: 35px;
        background: #fafafb;
        border-radius: 6px;
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        color: #434e5e;
        margin-bottom: 13px;
        line-height: 35px;
        padding-left: 15px;
      }

      &__itemChoose {
        display: flex;
        justify-content: space-between;
        height: 35px;
        background: rgba(237, 249, 243);
        border-radius: 6px;
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        color: #30c690;
        margin-bottom: 13px;
        line-height: 35px;
        padding-left: 15px;
      }
      .InspectionRecordFormContainer-label__con__itemIcon {
        width: 11px;
        height: 7px;
        margin-right: 17px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .collapse {
        margin-top: 10px;
        height: 26px;
        background: #ececef;
        border-radius: 6px;
        font-size: 13px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
        color: #818183;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;

        img {
          width: 13px;
          height: 13px;
        }

        .up {
          transform: rotate(180deg);
        }

        .down {
          transform: rotate(0deg);
        }
      }
    }
  }
  &-comment {
    background: #ffffff;
    border-radius: 10px;
    padding: 17px 15px 17px 15px;
    margin-bottom: 24px;
    &__top {
      font-size: 16px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 500;
      color: #081c38;
      span {
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 500;
        color: #8c939d;
      }
    }
    &__textarea {
      width: 97%;
      margin-top: 17px;
      background: #fafafb;
      border-radius: 0.5rem;
      border: 1px solid #ebeff4;
      padding-left: 11px;
      padding-top: 8px;
      height: 70px;
      font-size: 16px;
      font-family:
        PingFangSC,
        PingFang SC;
      color: #bcc1c7;
    }
    &__image {
      margin-top: 12px;
      .imgBox {
        width: 80px;
        height: 80px;
        background: #fafafb;
        border-radius: 6px;
        border: 2px dashed #ebeff4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .imgCon {
          width: 24px;
          height: 24px;
          margin-left: 28px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .imgName {
          margin-top: 10px;
          font-size: 12px;
          font-family:
            PingFangSC,
            PingFang SC;
          font-weight: 400;
          color: #8c939d;
          text-align: center;
        }
      }
    }
  }
  &-info {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 14px 15px 14px;
    &__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      &__left {
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        color: #8c939d;
      }
      &__right {
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        color: #bcc1c7;
      }
    }
  }
}
.InspectionRecordFormContainer-foot {
  height: 65px;
  background: #ffffff;
  box-shadow: 0px -4px 8px 0px rgba(8, 28, 56, 0.04);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  &__cancel {
    width: 40%;
    height: 45px;
    background: #f2f3f5;
    border-radius: 22px;
    text-align: center;
    line-height: 45px;
    font-size: 16px;
    font-family:
      PingFangSC,
      PingFang SC;
    font-weight: 500;
    color: #8c939d;
  }
  &__submit {
    margin-left: 25px;
    width: 40%;
    height: 45px;
    background: linear-gradient(90deg, #33b8ff 0%, #2499ff 100%);
    border-radius: 22px;
    text-align: center;
    line-height: 45px;
    font-size: 16px;
    font-family:
      PingFangSC,
      PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
.emptyLabel {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 10px;
}
.adm-popup-body {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  @media only screen and (min-width: 1024px) {
    & {
      max-width: 500px;
      margin: auto;
    }
  }

}
.my-popup {
  .adm-picker-header-button:nth-child(1) {
    font-size: 15px;
    font-family:
      PingFangSC,
      PingFang SC;
    font-weight: 400;
    color: #8f98a4;
    cursor: pointer;
  }
  .adm-picker-header-title {
    font-size: 16px;
    font-family:
      PingFangSC,
      PingFang SC;
    font-weight: 500;
    color: #081c38;
  }
}

@root-entry-name: default;