.vistor {
  // height: calc(100vh - 96px);
  overflow: auto;

  .header-bg {
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('../../assets/images/topbg.png');
    height: 140px;

    .name {
      font-size: 24px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 600;
      color: #ffffff;
      letter-spacing: 1px;
      padding-top: 36px;
      margin-left: 32px;
    }
  }

  .list {
    margin-top: -45px;
    min-height: 50px;
    .list-item {
      background: #ffffff;
      border-radius: 10px;
      padding-inline: 15px;
      width: 85%;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid #ebeff4;
        width: 100%;
        padding-block: 10px;
        flex: 1;

        .head {
          display: flex;
          align-items: center;
          width: 100%;

          .info {
            width: 50%;
          }
        }

        .info {
          display: flex;
          align-items: center;
          height: min-content;
          padding-block: 10px;

          .label {
            width: 75px;
            height: min-content;
            text-align: right;
            height: 21px;
            font-size: 15px;
            font-family:
              PingFangSC,
              PingFang SC;
            font-weight: 500;
            color: #8c939d;
          }

          .value {
            flex: 1;
            font-size: 15px;
            font-family:
              PingFangSC,
              PingFang SC;
            font-weight: 400;
            color: #081c38;
          }
        }
      }

      .footer {
        padding-block: 16px;
        font-size: 12px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
        color: #bcc1c7;
      }
    }
  }

  .back-up {
    position: fixed;
    bottom: 80px;
    right: 8px;
    cursor: pointer;

    img {
      width: 44px;
      height: 44px;
    }
  }
}

@root-entry-name: default;