.my-popup {
  .popup-header {
    height: 50px;
    background: #FFFFFF;
    border-radius: 14px 14px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 18px;

    // border-bottom: 1px solid #DDDADD;
    .title {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #081C38;
    }

    .close {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #8F98A4;
      cursor: pointer;
    }

    .ok {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #499AFF;
      cursor: pointer;
    }
  }

  .popup-content {
    max-height: 65vh;
    overflow-y: auto;

    .tabs {
      display: flex;
      justify-content: space-around;
      height: 40px;
      align-items: center;
      gap: 5%;

      .tab-item {
        font-size: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #898B8C;
        cursor: pointer;
      }

      .active {
        color: #384D72;
      }
    }

    .time-render {
      display: flex;
      gap: 8px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #8085E6;
      justify-content: center;
      align-items: center;
      height: 50px;
      border-block: 1px solid #ecebeb;
      padding: 0 13px;

      .start,
      .end {
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #8085E6;
        flex: 1;
        width: 100%;
        border-bottom: 2px solid #8085E6;
        text-align: center;
        opacity: 0.5;
        height: 20px;
        line-height: 20px;
      }

      .text {
        color: #666767;
      }

      .active {
        opacity: 1;
      }
    }
  }
}

.date-box {
  // width: 250px;
  height: 34px;
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #EEEEEE;
  margin-top: 8px;
  padding-left: 12px;
  padding-right: 4px;
  display: flex;
  align-items: center;

  .date-input {
    height: 34px;
    --font-size: 13px;
    flex: 1;
    line-height: 34px;
  }

  .placeholder {
    font-size: 13px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #B6B8BB;
  }

  .icon {
    width: 11px;
    height: 6px;
  }

  .open {
    transform: rotate(180deg);
  }
}
@root-entry-name: default;