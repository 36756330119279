.form-member-content {
  .tag-list-box {
    padding-inline: 22px;

    .member-tag-list {
      max-height: 34px;
      overflow: hidden;
    }

    .collapse {
      margin-top: 10px;
      height: 26px;
      background: #F7F7F7;
      border-radius: 6px;
      font-size: 13px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #878787;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      cursor: pointer;

      img {
        width: 13px;
        height: 13px;
      }

      .up {
        transform: rotate(180deg);
      }

      .down {
        transform: rotate(0deg);
      }
    }
  }

  .member-search {
    padding-block: 10px;
    padding-inline: 18px;
    position: relative;

    .adm-search-bar .adm-search-bar-input-box {
      border: 1px solid #E6E6E6;
      height: 32px;
    }

    .member-search-btn {
      width: 56px;
      height: 24px;
      background: linear-gradient(90deg, #4CA9FF 0%, #4579FF 100%);
      --border-radius: 19px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 22px;
      top: 14px;
      cursor: pointer;
    }
  }

  .member-list {
    border-top: 1px solid #DDDADD;
    padding-block: 13px;
    padding-inline: 22px;
    font-weight: 400;
    color: #6B7787;
    font-size: 14px;
    max-height: 48vh;
    overflow-y: auto;
    .adm-checkbox-custom-icon {
      width: 18px;
      height: 18px;
    }
    .letter {
      font-size: 10px;
    }
  }
}
@root-entry-name: default;