
.my-empty {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-block: 47px;
  margin: auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .empty-text {
    font-size: 15px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #8C939D;
    margin-top: 14px;
  }
}
@root-entry-name: default;