.my-del-tag {
  background: #F5F5F8;
  border-radius: 6px;
  font-size: 13px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #434E5E;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 11px;
  padding: 7px;
  .del-box {
    width: 15px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 13px;
      height: 13px;
    }
  }
}
@root-entry-name: default;