.inspection-analysis-detail {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 10px 0px;
  margin-bottom: -50px;
  background-color: #FFFFFF;
  min-height: calc(100vh - 20px);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 7px;
    padding-right: 13px;
    height: 33px;

    .search {
      width: 225px;
      height: 33px;
      position: relative;
      .adm-search-bar {
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      .adm-search-bar-input-box-icon {
        display: none;
      }

      .adm-search-bar-input-box {
        width: 225px;
        height: 33px;
        border-radius: 17px;
        border: 1px solid #8FB4FA;
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #8FB4FA;
        line-height: 33px;

        .adm-input-element {
          text-align: center;
          color: #8FB4FA;
        }
      }

      .placeholder {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #8FB4FA;
        gap: 7px;
        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    .download {
      width: 88px;
      height: 30px;
      background: #8CB1FA;
      border-radius: 15px;
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .more {
    display: flex;
    justify-content: end;
    padding-right: 13px;

    .more-btn {
      display: flex;
      height: 35px;
      cursor: pointer;

      .more-btn-l {
        width: 15px;
        height: 100%;
        background-image: url('../../assets/icons/more-l.png');
        background-repeat: no-repeat;
        background-size: 15px 34px;
        background-position: 1px center;
      }

      .more-btn-c {
        width: 76px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-image: url('../../assets/icons/more-c.png');
        background-repeat: no-repeat;
        background-size: 100%;

        img {
          width: 8px;
          height: 11px;
        }
      }

      .more-btn-r {
        width: 15px;
        height: 100%;
        background-image: url('../../assets/icons/more-r.png');
        background-repeat: no-repeat;
        background-size: 15px 34.1px;
        background-position: -4px -.1px;
      }
    }
  }
}
@root-entry-name: default;