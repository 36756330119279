.bottom-btn-box {
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px -4px 8px 0px rgba(8, 28, 56, 0.02);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 1024px) {
    & {
      max-width: 500px;
      margin: auto;
    }
  }

  .btn {
    width: 90%;
    height: 41px;
    background: linear-gradient(90deg, #33B8FF 0%, #2499FF 100%);
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;

    img {
      width: 14px;
      height: 14px;
    }
  }
}
@root-entry-name: default;