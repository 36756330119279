.page-place-create {
  .adm-list-body {
    border: none;

    .adm-list-item {
      .adm-list-item-content {
        border-top: none;
        // border-bottom: 1px solid #EBEFF4;
        border-bottom: var(--border-bottom);
        position: relative;
        padding-right: 2px;
        // &::before {
        //   display: block;
        //   content: ' ';
        //   border-radius: 50%;
        //   width: 5px;
        //   height: 5px;
        //   background: #FF7011;
        //   position: absolute;
        //   left: -10px;
        //   top: 20px;
        // }

        .adm-form-item-label {
          font-size: 15px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #081C38;
        }

        .adm-input-element {
          text-align: right;
          font-size: 15px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #282727;
        }

        .adm-form-item-feedback-error {
          text-align: right;
        }
      }

      .adm-form-item-child-position-normal {
        text-align: right;
        font-size: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #282727;
      }
    }
  }

  .form-box {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 14px 14px 14px 4px;

    .area-form-down {
      .adm-list-item-content-arrow {
        transform: rotate(90deg);
      }
    }
    .area-form-up {
      .adm-list-item-content-arrow {
        transform: rotate(-90deg);
      }
    }
  }

  .add-label-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 26px;
    padding-inline: 20px;

    .import-label {
      font-size: 13px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #499AFF;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      img {
        width: 10px;
        height: 12px;
      }
    }

    .add-label {
      font-size: 13px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FF7011;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      img {
        width: 13px;
        height: 13px;
      }
    }
  }
}
@root-entry-name: default;