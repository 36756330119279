.page-vistor-create {
  padding-inline: 15px;
  margin-top: 20px;

  .adm-list-body {
    background-color: transparent;
    border: none;

    .adm-list-item {
      background-color: transparent;

      .adm-list-item-content {
        border-top: none;
        border-bottom: 1px solid #EBEFF4;
        position: relative;
        padding-right: 2px;

        &::before {
          display: block;
          content: ' ';
          border-radius: 50%;
          width: 5px;
          height: 5px;
          background: #FF7011;
          position: absolute;
          left: -10px;
          top: 20px;
        }

        .adm-form-item-label {
          font-size: 15px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #081C38;
        }

        .adm-input-element {
          text-align: right;
          font-size: 15px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #282727;
        }

        .adm-form-item-feedback-error {
          text-align: right;
        }
      }

      .adm-form-item-child-position-normal {
        text-align: right;
        font-size: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #282727;
      }
    }
  }

  .form-box {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 14px 14px 14px 4px;
  }

  .form-box1 {
    margin-top: 15px;
    height: 223px;
  }

  .form-box2 {
    margin-top: 15px;
    // height: 223px;
  }

  .car_input {
    .car_input_text {
      text-align: right;
      font-size: 15px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
    }

    .placeholder {
      text-align: right;
      font-size: 15px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #BCC1C7;
    }
  }

  .code {
    padding-block: 5px;

    .adm-input-element {
      text-align: left !important;
    }

    .adm-form-item-feedback-error {
      text-align: left !important;
    }

    .adm-form-item-child-position-normal {
      text-align: left !important;
    }

    .code-btn {
      width: 110px;
      height: 38px;
      background: #E7F2FF;
      border-radius: 8px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #499AFF;
    }
  }

}

.up_page {
  height: calc(100vh - 85vw);
  overflow: auto;
  padding-bottom: 24px;
}
@root-entry-name: default;