.login {
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  gap: 62px;
  margin-bottom: -50px;
  background-color: #FFFFFF;
  min-height: calc(100vh);
  .book-img {
    width: 65%;
    margin-top: 68px;
  }

  .login-btn {
    width: 200px;
    height: 47px;
    background: #30A3FF;
    box-shadow: 0px 10px 15px 0px rgba(0, 136, 244, 0.2);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
  }
}
@root-entry-name: default;