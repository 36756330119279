.inspection-analysis {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 13px 15px;

  .card {
    // width: 344px;
    height: 356px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #EEEEEE;
    padding: 20px 18px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    .header {
      display: flex;
      justify-content: space-between;

      .label {
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #152E5A;
      }

      .detail {
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #8AB0FA;
        margin-right: 15px;
        cursor: pointer;
      }
    }
    .duration-chart {
      width: 100%;
      height: 260px;
      margin-top: 30px;
    }
  }
}
@root-entry-name: default;