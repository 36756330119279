.page-label {
  padding: 18px 15px 70px;

  .list {
    display: flex;
    flex-direction: column;
    padding: 18px 20px 20px 15px;
    background: #ffffff;
    border-radius: 10px;
    gap: 10px;

    .list-item {
      background: #fafafb;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 12px;
      padding-block: 11px;

      .label-title {
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
        color: #434e5e;
        max-width: 80%;
      }

      .label-del {
        cursor: pointer;
        padding: 2px;

        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    .import-box {
      display: flex;
      gap: 10px;
      font-size: 13px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 500;
      color: #499aff;

      .import {
        font-size: 13px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 500;
        color: #499aff;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 9px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }
  }

  .labelModal {
    .adm-modal-content {
      padding-inline: 30px;
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 10px;

      .close {
        background: #f2f3f5;
        border-radius: 17px;
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 500;
        color: #8c939d;
      }

      .submit {
        background: linear-gradient(90deg, #4ca9ff 0%, #4579ff 100%);
        border-radius: 17px;
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .adm-form-item.adm-form-item-horizontal.adm-list-item {
      border-radius: 6px;
      border: 1px solid #e2ebf9;
      margin-top: 10px;
    }

    .adm-list-item-content-main {
      padding: 5px 0;
    }

    .adm-list-default .adm-list-body {
      border: none;
    }

    .adm-form .adm-form-footer {
      padding-inline: 0px;
    }

    .add {
      font-size: 13px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 500;
      color: #5578ff;
      display: flex;
      justify-content: flex-end;
      margin-block: 6px;

      .add-btn {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
      }

      img {
        width: 13px;
        height: 13px;
        margin-bottom: 2px;
      }
    }
  }
}
@root-entry-name: default;