.form-time-content {
  .tag-list-box {
    padding-bottom: 13px;
    padding-inline: 22px;
    .time-tag-list1 {
      max-height: 34px;
      overflow: hidden;
    }
    .collapse {
      margin-top: 10px;
      height: 26px;
      background: #F7F7F7;
      border-radius: 6px;
      font-size: 13px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #878787;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      cursor: pointer;

      img {
        width: 13px;
        height: 13px;
      }

      .up {
        transform: rotate(180deg);
      }

      .down {
        transform: rotate(0deg);
      }
    }
  }

  .time-list {
    border-top: 1px solid #DDDADD;
    padding-block: 13px;
    padding-inline: 22px;
    width: auto;
  }
}
@root-entry-name: default;