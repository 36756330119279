@import './vars.less';

body {
  margin: 0;
}

:root:root {
  --adm-color-primary: @primary-color;
}
.tabBarIcon {
  width: 1.375rem;
  height: 1.375rem;
}

@root-entry-name: default;