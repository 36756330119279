.vistor-code {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 62px;
  margin-bottom: -50px;
  background-color: #FFFFFF;
  min-height: calc(100vh - 20px);
}
@root-entry-name: default;