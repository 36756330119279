.InspectionRecordContainer {
  background-color: #ffffff;
  position: relative;
  // height: calc(100vh - 96px);
  &-top {
    background-image: url(../../assets/images/InspectionRecordBg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 140px;
    padding: 28px 32px;
    box-sizing: border-box;
    // position: relative;
    &__title {
      // position: absolute;
      font-size: 20px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 600;
      color: #ffffff;
      // left: 32px;
      // top: 28px;
    }
    &__name {
      padding: 6px 12px;
      // position: absolute;
      // left: 32px;
      // top: 69px;
      margin-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: linear-gradient(90deg, #3492f7 0%, #2563f2 100%);
      border-radius: 1.0625rem;
      width: max-content;

      &__icon {
        width: 22px;
        height: 22px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &__user {
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  &-center {
    width: 100%;
    background: #ffffff;
    border-radius: 20px 20px 0 0;
    // position: absolute;
    transform: translateY(-30px);
    // top: 120px;
    &__top {
      margin-top: 10px;
      padding: 15px 18px 15px 18px;
      display: flex;
      justify-content: space-between;
      .adm-search-bar .adm-search-bar-input-box {
        border: 1px solid #bcc1c7;
        border-radius: 20px;
        background-color: #ffffff;
        width: 129px;
      }
      &__btn {
        width: 121px;
        height: 31px;
        line-height: 31px;
        background: linear-gradient(90deg, #33b8ff 0%, #2499ff 100%);
        border-radius: 17px;
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
      }
    }
    &__con {
      display: flex;
      background-color: #ffffff;
      &__left {
        background-color: #f3f3f3;
        align-self: flex-start;
        height: calc(100vh - 240px);
        width: 105px;
        position: relative;
        .loadingText {
          position: absolute;
          left: 15%;
          top: 20%;
        }
        .loadingBox {
          position: absolute;
          left: 30%;
          top: 20%;
        }
      }
      &__right {
        width: 100%;
        margin-left: 20px;
        margin-top: 10px;
        margin-right: 20px;
        &__item {
          height: 90px;
          background: #fafafb;
          margin-bottom: 15px;
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          text-align: center;
          align-items: center;
          &__class {
            width: 35%;
            height: 90px;
            background: #f5f5f8;
            border-radius: 10px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10px;
            padding-right: 10px;
          }
          &__class div:nth-child(1) {
            font-size: 14px;
            font-family: DINAlternate, DINAlternate;
            font-weight: bold;
            color: #8c939d;
          }
          &__class div:nth-child(2) {
            font-size: 12px;
            font-family:
              PingFangSC,
              PingFang SC;
            font-weight: 400;
            color: #8c939d;
          }
          &__record {
            width: 32%;
            font-size: 17px;
            font-family:
              PingFangSC,
              PingFang SC;
            font-weight: bold;
            color: #8c939d;
            border-right: 1px solid #ebeff4;
            // padding-left: 10px;
            // padding-right: 15px;
          }
          &__btn {
            width: 32%;
            font-size: 14px;
            font-family:
              PingFangSC,
              PingFang SC;
            font-weight: 500;
            color: #499aff;
            cursor: pointer;
            // margin-left: 20px;
            // margin-right: 20px;
          }
        }
        &__item:hover {
          background: #f5f7ff;
          .InspectionRecordContainer-center__con__right__item__class {
            background: #e7f2ff;
          }
        }
      }
    }
  }
}

@root-entry-name: default;