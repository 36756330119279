.tag-popup {
  .tag-popup-content {
    // width: 100vw;
    .popup-header {
      height: 50px;
      background: #ffffff;
      border-radius: 14px 14px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 18px;

      // border-bottom: 1px solid #DDDADD;
      .title {
        font-size: 16px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 500;
        color: #081c38;
      }

      .close {
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
        color: #8f98a4;
        cursor: pointer;
      }

      .ok {
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 500;
        color: #499aff;
        cursor: pointer;
      }
    }

    .tag-list {
      padding: 22px 15px;
    }
  }

  .check-list {
    .tag-checked-all {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 16px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 500;
      color: #152e5a;
      margin-bottom: 14px;

      .line {
        width: 3px;
        height: 13px;
        background: #677fd4;
        border-radius: 2px;
      }
    }

    .tag-checkbox {
      background: #f9f9f9;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .c_item {
        display: flex;
        flex-direction: column;
        position: relative;
        gap: 10px;
      }
      .c_item:not(:first-child) {
        &::after {
          display: block;
          content: ' ';
          position: absolute;
          top: -20px;
          left: 0;
          right: 0;
          height: 1px;
          background: #dddddd;
          margin-block: 8px;
        }
      }
      .tag-head {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 15px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 500;
        color: #999999;
        line-height: 15px;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .border-line {
        height: 1px;
        background: #dddddd;
        margin-block: 8px;
      }

      .check-all {
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 600;
        color: #707f99;
      }

      .check-label {
        font-size: 14px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
        color: #687484;
      }

      .adm-checkbox .adm-checkbox-custom-icon {
        width: 18px;
        height: 18px;
      }

      .check-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@root-entry-name: default;