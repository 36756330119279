.form-time-content {
  .time-tag-list {
    padding-bottom: 13px;
    padding-inline: 22px;
  }

  .time-list {
    border-top: 1px solid #DDDADD;
    padding-block: 13px;
    width: 100%;
    padding-inline: 22px;
  }
}
@root-entry-name: default;