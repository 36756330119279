.page-group-create {
  .adm-list-body {
    border: none;
    .adm-input  {
      font-size: 15px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #152E5A;
    }
    .adm-list-item {
      .adm-list-item-content {
        border-top: none;
        // border-bottom: 1px solid #EBEFF4;
        border-bottom: var(--border-bottom);
        position: relative;
        padding-right: 2px;
        // &::before {
        //   display: block;
        //   content: ' ';
        //   border-radius: 50%;
        //   width: 5px;
        //   height: 5px;
        //   background: #FF7011;
        //   position: absolute;
        //   left: -10px;
        //   top: 20px;
        // }

        .adm-form-item-label {
          font-size: 15px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #081C38;
        }

        .adm-input-element {
          text-align: right;
          font-size: 15px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #282727;
        }

        .adm-form-item-feedback-error {
          text-align: right;
        }
      }

      .adm-form-item-child-position-normal {
        text-align: right;
        font-size: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #282727;
      }
    }
  }

  .form-box {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 14px 14px 14px 4px;

    .area-form {
      .adm-list-item-content-arrow {
        display: none;
      }
    }

    .del-box {
      display: flex;
      flex-direction: column;
      align-items: end;
      .line {
        height: 1px;
        background-color: #EBEFF4;
        transform: scaleY(0.5);
        width: 100%;
      }

      .delbtn {
        margin-top: 16px;
        width: 49px;
        height: 25px;
        border-radius: 8px;
        border: 1px solid #979797;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #979797;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .add {
    font-size: 13px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #FF7011;
    display: flex;
    justify-content: end;
    padding-top: 12px;
    background: #F5F5F8;

    .add-btn {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      padding-right: 5px;
    }

    img {
      width: 13px;
      height: 13px;
      margin-bottom: 2px;
    }
  }
  .form-box3 {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 5px 14px 5px 0px;
    .adm-form-item-label {
      line-height: 30px;
    }
    .adm-list-item-content {
      border-top: none;
      border-bottom: 1px solid #EBEFF4;
      position: relative;
      padding-right: 2px;
      padding-left: 10px;
      &::before {
        display: block;
        content: ' ';
        border-radius: 50%;
        width: 5px;
        height: 5px;
        background: #FF7011;
        position: absolute;
        left: -5px;
        top: 23px;
      }

      .adm-form-item-label {
        font-size: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #081C38;
      }

      .adm-input-element {
        text-align: right;
        font-size: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #282727;
      }

      .adm-form-item-feedback-error {
        text-align: right;
      }
    }
  }
}
@root-entry-name: default;