.personal {
  padding: 15px;
  background-image: url('../../assets/images/me_bg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;

  .header {
    height: 70px;
    display: flex;
    align-items: center;
    gap: 22px;
    margin-top: 40px;
    margin-bottom: 24px;

    .avater {
      width: 66px;
      height: 66px;
      background: #FFFFFF;
      border-radius: 14px;
      border: 2px solid #FFFFFF;
    }

    .name {
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 28px;
      cursor: pointer;
    }
  }

  .centent {
    min-height: 324px;
    background: #FFFFFF;
    border-radius: 10px;

    .adm-list-item-content {
      padding-block: 6px;
    }

    .adm-list-item-content-prefix {
      display: flex;
      align-items: center;

      .icon {
        width: 34px;
        height: 34px;
      }
    }

    .adm-list-item-content-main {
      font-weight: 400;
      color: #081C38;
    }

    .adm-list-item-content-extra {
      font-weight: 400;
      color: #050505 29%;
    }
  }

  .login-out {
    height: 45px;
    background: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    margin: auto;
    bottom: 74px;
    left: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #8C939D;
    cursor: pointer;

    @media only screen and (min-width: 1024px) {
      & {
        max-width: 460px;
      }
    }
  }
}
@root-entry-name: default;