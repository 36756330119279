.area-content {
  border-top: 1px solid #DDDADD;
  padding: 24px 28px;

  .adm-radio-content {
    font-size: 15px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding-left: 15px;
  }
}
@root-entry-name: default;