@import "~@/style/vars.less";

.@{layout} {
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;

    @media only screen and (min-width: 1024px) {
        & {
            max-width: 500px;
            margin: auto;
            max-height: 100vh;
            overflow-y: auto;
            box-shadow: 0px 0px 3px 4px rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #9f9f9f;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: #ededed;
        }
    }

    .@{layout}-main {
        flex: 1;
        background: #F5F5F8;
        // padding-top: 45px;
        padding-bottom: 50px;
        .adm-nav-bar {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 11;

            @media only screen and (min-width: 1024px) {
                & {
                    max-width: 476px;
                    margin: auto;
                }
            }
        }
    }

    .@{layout}-tabbar {
        flex: 0;
        border-top: solid 1px var(--adm-color-border);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;

        @media only screen and (min-width: 1024px) {
            & {
                max-width: 500px;
                margin: auto;
            }
        }
    }
}
@root-entry-name: default;