.page-grouping {
  padding: 13px 15px 70px;

  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .list-group {
      height: 55px;
      background: #FFFFFF;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 15px;

      .name {
        font-size: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #081C38;
        max-width: 75%;
        overflow: hidden;
      }

      .btn {
        cursor: pointer;
        width: 55px;
        height: 28px;
        background: #E7F2FF;
        border-radius: 6px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #499AFF;
        text-align: center;
        line-height: 28px;
      }
    }
  }
}
@root-entry-name: default;