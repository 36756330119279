.confirm-mask-box {
  display: flex;
  align-items: center;
  justify-content: center;
  .mask-content {
    width: 268px;
    height: 152px;
    background: #FFFFFF;
    border-radius: 12px;
    // padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 26px;
    align-items: center;

    .title {
      font-size: 15px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #081C38;
      margin-top: 41px;
    }

    .footer {
      display: flex;
      gap: 13px;

      .btn {
        width: 96px;
        height: 34px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        text-align: center;
        line-height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        --border-radius: 17px;
      }

      .col {
        background: #F2F3F5;
        color: #8C939D;
      }

      .ok {
        background: linear-gradient(90deg, #4CA9FF 0%, #4579FF 100%);
        color: #FFFFFF;
      }
    }
  }
}
@root-entry-name: default;